import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Alert,
  AlertTitle,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { USER_ROLES } from '../../SharedLogic';
import styles from './SelectUsers.module.css';
import { red } from '@mui/material/colors';

const ASC_ORDER = 'asc';
const DESC_ORDER = 'desc';
const USER_ID_CELL = 'UserId';
const GROUP_CELL = 'Group';

const EditSelectUsers = ({
  selectedUsersAndRoles,
  setSelectedUsersAndRoles,
  usersSortOrder,
  setUsersSortOrder,
  groupsSortOrder,
  setGroupsSortOrder,
  sortBy,
  setSortBy,
  selectedFilteredGroups,
  setSelectedFilteredGroups,
  preSelectedUsersAndRoles,
  inactivatedUserAndRoles,
  editBatchesList,
  showAlert,
  setShowAlert,
  userAlertMessage,
  setUserAlertMessage,
}) => {
  const sortTable = useCallback(() => {
    let sortOrder = usersSortOrder;
    if (sortBy === GROUP_CELL) {
      sortOrder = groupsSortOrder;
    }
    return selectedUsersAndRoles.sort((a, b) => {
      if (sortOrder === ASC_ORDER) {
        return a[sortBy].localeCompare(b[sortBy]);
      } else {
        return b[sortBy].localeCompare(a[sortBy]);
      }
    });
  }, [groupsSortOrder, selectedUsersAndRoles, sortBy, usersSortOrder]);

  const getUsersRolesListData = useCallback(() => {
    const sortedUsers = sortTable();
    return sortedUsers.map((user) => ({
      label: user.UserId,
      value: user.UserId,
      group: user.Group,
      roles: user.roles,
    }));
  }, [sortTable]);

  const usersRolesListData = useMemo(
    () => getUsersRolesListData(),
    [getUsersRolesListData]
  );

  const filteredUsers = useMemo(() => {
    return selectedFilteredGroups.length > 0
      ? usersRolesListData.filter((user) =>
          selectedFilteredGroups.includes(user.group)
        )
      : usersRolesListData;
  }, [usersRolesListData, selectedFilteredGroups]);

  const handleRoleSelected = (event, userId, roleIndex) => {
    const updatedUsersRoles = [...selectedUsersAndRoles];

    const originalUserIndex = updatedUsersRoles.findIndex(
      (user) => user.UserId === userId
    );

    if (originalUserIndex !== -1) {
      const role = updatedUsersRoles[originalUserIndex].roles[roleIndex];
      role.checked = event.target.checked;
      setSelectedUsersAndRoles(updatedUsersRoles);

      constructAlertMessage(
        userId,
        updatedUsersRoles[originalUserIndex].roles,
        editBatchesList
      );
    }
  };

  const constructAlertMessage = (userId, roles, editBatchesList) => {
    const uncheckedRoles = {
      tier1: false,
      tier2: false,
      tier3: false,
    };

    roles.forEach((role) => {
      if (!role.checked) {
        uncheckedRoles[role.label] = true;
      }
    });

    const userInProgressTasks = editBatchesList.reduce((acc, batch) => {
      const userTasks = batch.WorkStatus?.userInProgressTasks[userId] || {};
      if (userTasks[1] > 0) {
        acc[1] = (acc[1] || 0) + userTasks[1];
      }
      if (userTasks[2] > 0) {
        acc[2] = (acc[2] || 0) + userTasks[2];
      }
      if (userTasks[3] > 0) {
        acc[3] = (acc[3] || 0) + userTasks[3];
      }
      return acc;
    }, {});

    const alertMessages = [];
    if (uncheckedRoles.tier1 && userInProgressTasks[1]) {
      alertMessages.push(
        `There are currently ${userInProgressTasks[1]} task(s) in progress in Tier 1`
      );
    }
    if (uncheckedRoles.tier2 && userInProgressTasks[2]) {
      alertMessages.push(
        `There are currently ${userInProgressTasks[2]} task(s) in progress in Tier 2`
      );
    }
    if (uncheckedRoles.tier3 && userInProgressTasks[3]) {
      alertMessages.push(
        `There are currently ${userInProgressTasks[3]} task(s) in progress in Tier 3`
      );
    }

    if (alertMessages.length > 0) {
      const finalAlertMessage = alertMessages
        .join(', ')
        .replace(/,([^,]*)$/, ' and$1');

      setShowAlert((prev) => ({
        ...prev,
        [userId]: true,
      }));
      setUserAlertMessage((prev) => ({
        ...prev,
        [userId]: {
          alertMessage: finalAlertMessage,
          disclaimer: `<strong>Disclaimer:</strong> These are the current progress details for this user role. Please proceed with caution.`,
        },
      }));
    } else {
      setShowAlert((prev) => ({
        ...prev,
        [userId]: false,
      }));
      setUserAlertMessage((prev) => ({
        ...prev,
        [userId]: {},
      }));
    }
  };

  const toggleSortOrder = (cell) => {
    setSortBy(cell);
    if (cell === USER_ID_CELL) {
      setUsersSortOrder((prevOrder) =>
        prevOrder === ASC_ORDER ? DESC_ORDER : ASC_ORDER
      );
    } else if (cell === GROUP_CELL) {
      setGroupsSortOrder((prevOrder) =>
        prevOrder === ASC_ORDER ? DESC_ORDER : ASC_ORDER
      );
    }
  };

  const uniqueGroups = useMemo(() => {
    return [...new Set(selectedUsersAndRoles.map((user) => user.Group))].sort();
  }, [selectedUsersAndRoles]);

  const enableInactiveUserAndRoles = (userRole, userEmail) => {
    const preSelectedRoles = preSelectedUsersAndRoles[userEmail] || [];
    const inactivatedRoles = inactivatedUserAndRoles[userEmail] || [];
    const isInactivated = inactivatedRoles.includes(userRole);
    const isChecked =
      preSelectedRoles.includes(userRole) &&
      !selectedUsersAndRoles.some(
        (user) =>
          user.UserId === userEmail &&
          user.roles.some((role) => role.label === userRole && role.checked)
      );

    return isInactivated || isChecked ? red[500] : '';
  };

  return (
    <>
      <Autocomplete
        multiple
        options={uniqueGroups}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option}
          </li>
        )}
        className={styles.groupFilter}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Filter by Group" />
        )}
        value={selectedFilteredGroups}
        onChange={(event, newValue) => {
          setSelectedFilteredGroups(newValue.sort());
        }}
      />

      <TableContainer
        className={`${styles.usersTable} ${styles.scrollableContainer}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                className={styles.clickableCell}
                onClick={() => toggleSortOrder(USER_ID_CELL)}
              >
                User {usersSortOrder === ASC_ORDER ? '▲' : '▼'}
              </TableCell>
              <TableCell
                className={styles.clickableCell}
                onClick={() => toggleSortOrder(GROUP_CELL)}
              >
                Group {groupsSortOrder === ASC_ORDER ? '▲' : '▼'}
              </TableCell>
              {USER_ROLES.map((role) => (
                <TableCell key={role}>{role}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((row) => (
              <React.Fragment key={row.value}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.group}
                  </TableCell>
                  {row.roles.map((role, roleIndex) => (
                    <TableCell key={role.label}>
                      <Checkbox
                        sx={{
                          color: enableInactiveUserAndRoles(
                            role.label,
                            row.label
                          ),
                        }}
                        checked={
                          role.checked ||
                          selectedUsersAndRoles[row.label]?.includes(role.label)
                        }
                        onChange={(event) =>
                          handleRoleSelected(event, row.value, roleIndex)
                        }
                      />
                    </TableCell>
                  ))}
                </TableRow>
                {showAlert[row.value] && (
                  <TableRow>
                    <TableCell colSpan={USER_ROLES.length + 2}>
                      <Alert severity="warning">
                        <AlertTitle>
                          {userAlertMessage[row.value]?.alertMessage}
                        </AlertTitle>
                        <div
                          style={{
                            marginTop: '10px',
                            padding: '10px',
                            border: '1px solid red',
                            borderRadius: '5px',
                            backgroundColor: '#ffe6e6',
                            color: 'red',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: userAlertMessage[row.value]?.disclaimer,
                          }}
                        />
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EditSelectUsers;
