import React from 'react';
import CampaignGuidanceEdit from './CampaignGuidanceEdit';
import { useSelector, useDispatch } from 'react-redux';
import { selectCampaign } from '../../redux/tasks/tasksSlice';
import {
  selectRole,
  selectCampaignGuidance,
  setCampaignGuidance,
} from '../../redux/tasks/tasksSlice';
import { isSupervisor, isWatcher } from './rolesUtil';
import { useGetApiConfigs } from '../../hooks';
import { cloneDeep } from 'lodash';
import { postData } from '../LabelingToolPage/LabelingToolPage.logic';

export const CampaignGuidance = () => {
  const apiConfigs = useGetApiConfigs();
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const isSupervisorRole = isSupervisor(role);
  const isWatcherRole = isWatcher(role);
  const selectedCampaign = useSelector(selectCampaign);
  const campaignGuidance = useSelector(selectCampaignGuidance);
  var campaignGuidanceList = cloneDeep(campaignGuidance);
  const emptyGuidance = '<p></p>';
  const valueOfCampaign =
    selectedCampaign && campaignGuidanceList[selectedCampaign]
      ? campaignGuidanceList[selectedCampaign]
      : emptyGuidance;

  const saveCampaignGuidance = async (text) => {
    campaignGuidanceList[selectedCampaign] = text;
    dispatch(setCampaignGuidance(campaignGuidanceList));

    await postData(
      `${apiConfigs.API_ENDPOINT}/uploadCampaignGuidance?campaignId=${selectedCampaign}`,
      { campaignGuidance: text }
    );
  };
  return (
    <div style={{ marginLeft: '4%' }}>
      <CampaignGuidanceEdit
        textToEdit={valueOfCampaign}
        isEditableRole={isSupervisorRole || isWatcherRole}
        saveCampaignGuidance={saveCampaignGuidance}
      />
    </div>
  );
};
export default CampaignGuidance;
