import React, { useEffect } from 'react';
import CampaignSummaryTable from './CampaignSummaryTable';
import { confirmAlert } from 'react-confirm-alert';
import {
  customUi,
  dialogBtns,
  buttonConfirm,
} from './updateCampaignDialog.module.css';
import { useDispatch } from 'react-redux';
import { setConfirmPopUpEnabled } from '../../../../redux/labelingTool/labelingToolSlice';
import { Button } from '@mui/material';

const UpdateCampaignDialog = ({
  confirmChanges,
  activityLogs,
  findingsList,
  editBatchesList,
}) => {
  const previousEditCampaignSummary = {
    ...activityLogs?.previousEditCampaignSummary,
  };
  const latestEditCampaignSummary = {
    ...activityLogs?.latestEditCampaignSummary,
  };

  const mapIdsToNames = (itemsList, ids, idKey, nameKey) => {
    return itemsList
      .filter((item) => ids.includes(item[idKey]))
      .map((item) => item[nameKey]);
  };

  previousEditCampaignSummary.batches = mapIdsToNames(
    editBatchesList,
    previousEditCampaignSummary.batches,
    'BatchId',
    'BatchName'
  );

  latestEditCampaignSummary.batches = mapIdsToNames(
    editBatchesList,
    latestEditCampaignSummary.batches,
    'BatchId',
    'BatchName'
  );

  previousEditCampaignSummary.findings = mapIdsToNames(
    findingsList,
    previousEditCampaignSummary.findings,
    'FindingId',
    'FindingName'
  );

  latestEditCampaignSummary.findings = mapIdsToNames(
    findingsList,
    latestEditCampaignSummary.findings,
    'FindingId',
    'FindingName'
  );

  const segregateUsersByTier = (editCampaignSummary) => {
    const tiers = {
      tier1: [],
      tier2: [],
      tier3: [],
      supervisor: [],
      watcher: [],
    };

    Object.entries(editCampaignSummary).forEach(([email, roles]) => {
      roles.forEach((role) => {
        if (tiers[role]) {
          tiers[role].push(email);
        }
      });
    });

    return tiers;
  };

  const previousTiers = segregateUsersByTier(
    previousEditCampaignSummary.usersAndRoles
  );
  const latestTiers = segregateUsersByTier(
    latestEditCampaignSummary.usersAndRoles
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let closeCompleteTask = null;
    confirmAlert({
      closeOnClickOutside: false,
      closeOnEscape: false,
      customUI: ({ onClose }) => {
        closeCompleteTask = onClose;
        return (
          <div className={customUi}>
            <section>
              <p style={{ fontWeight: 'bold' }}>
                Are you sure you want to proceed with the changes ?
              </p>
            </section>
            <CampaignSummaryTable
              previousEditCampaignSummary={previousEditCampaignSummary}
              latestEditCampaignSummary={latestEditCampaignSummary}
              previousTiers={previousTiers}
              latestTiers={latestTiers}
            />
            <footer className={dialogBtns}>
              <Button
                className={buttonConfirm}
                variant="outlined"
                onClick={() => {
                  dispatch(setConfirmPopUpEnabled(false));
                  confirmChanges(onClose);
                  onClose();
                }}
              >
                Confirm
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => {
                  onClose();
                  dispatch(setConfirmPopUpEnabled(false));
                }}
              >
                Cancel
              </Button>
            </footer>
          </div>
        );
      },
    });

    return () => {
      if (closeCompleteTask) {
        closeCompleteTask();
      }
    };
  });
  return null;
};

export default UpdateCampaignDialog;
