import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import {
  Column as VirtualizedColumn,
  Table as VirtualizedTable,
  defaultTableRowRenderer,
  CellMeasurerCache,
  CellMeasurer,
  SortDirection,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import _ from 'lodash';
import styles from './Table.module.css';

const Table = (props) => {
  const {
    headerHeight,
    rowHeight,
    width,
    height,
    data,
    columns,
    onRowSelect,
    selectedRowId,
    dynamicCellHeight,
    tableName,
    rowStyle,
    handleCheckboxChange = null,
    selectedFavorite = null,
  } = props;

  const [selectedRow, setSelectedRow] = useState(selectedRowId);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    if (data.find((row) => row.id === selectedRowId)) {
      setSelectedRow(selectedRowId);
      return;
    }
    setSelectedRow(-1);
  }, [data, selectedRowId]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const rowCount = sortedData.length ? sortedData.length : data.length;

  const rowGetter = ({ index }) =>
    sortedData.length ? sortedData[index] : data[index];

  const handleRowSelection = ({ rowData }) => {
    onRowSelect(rowData);
  };

  const rowRenderer = (props) => {
    const { className: classNameProp, ...other } = props;
    const className = classNames(classNameProp, styles.row, {
      [styles.selected]: selectedRow === props.rowData.id,
      [styles.disabledRow]: props.rowData.disabled,
    });

    return defaultTableRowRenderer({ className, ...other });
  };

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        minHeight: rowHeight,
      }),
    [rowHeight]
  );

  const computedRowHeight = dynamicCellHeight ? cache.rowHeight : rowHeight;
  const cellClassName = classNames({
    [styles.cell]: dynamicCellHeight,
  });

  const cellRenderer = ({ ...params }) => {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={params.columnIndex}
        key={params.dataKey}
        parent={params.parent}
        rowIndex={params.rowIndex}
      >
        <div className={cellClassName}>{params.cellData}</div>
      </CellMeasurer>
    );
  };

  const checkboxCellRenderer = ({
    columnIndex,
    key,
    parent,
    rowIndex,
    rowData,
    handleCheckboxChange,
    selectedFavorite,
  }) => {
    const campaignId = rowData.id;
    const isChecked = selectedFavorite && selectedFavorite === campaignId;

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckboxChange(campaignId)}
          />
        </div>
      </CellMeasurer>
    );
  };

  const sort = ({ sortBy, sortDirection }) => {
    const sortedData = sortData({ sortBy, sortDirection });

    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortedData(sortedData);
  };

  const sortData = ({ sortBy, sortDirection }) => {
    const newData = _.sortBy(data, (item) => item[sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newData.reverse();
    }

    return newData;
  };

  const headerRowRenderer = ({ className, columns, style }) => {
    const classes = classNames(className, styles.header);

    return (
      <div className={classes} role="row" style={{ ...style, paddingRight: 0 }}>
        {columns}
      </div>
    );
  };

  return (
    <VirtualizedTable
      deferredMeasurementCache={cache}
      width={width}
      height={height}
      headerHeight={headerHeight}
      headerStyle={{ padding: '5px' }}
      headerRowRenderer={headerRowRenderer}
      rowHeight={computedRowHeight}
      rowRenderer={rowRenderer}
      rowCount={rowCount}
      rowGetter={rowGetter}
      rowStyle={rowStyle}
      onRowClick={handleRowSelection}
      sort={sort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      className={tableName}
    >
      {columns.map((column) => (
        <VirtualizedColumn
          style={{ display: column.hidden ? 'none' : 'block', padding: '10px' }}
          key={column.dataKey}
          label={column.label}
          dataKey={column.dataKey}
          width={column.width || 100}
          cellRenderer={(params) => {
            if (column.type === 'checkbox') {
              return checkboxCellRenderer({
                ...params,
                handleCheckboxChange: handleCheckboxChange,
                selectedFavorite: selectedFavorite,
              });
            } else {
              return cellRenderer(params);
            }
          }}
        />
      ))}
    </VirtualizedTable>
  );
};

Table.propTypes = {
  /**
   * Header height
   */
  headerHeight: PropTypes.number,
  /**
   * Row height
   */
  rowHeight: PropTypes.number,
  /**
   * width of the table
   */
  width: PropTypes.number,
  /**
   * height of the table
   */
  height: PropTypes.number,
  /**
   * array of columns
   */
  columns: PropTypes.arrayOf(
    /**
     * Column
     */
    PropTypes.shape({
      label: PropTypes.node,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  /**
   * Data to render
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * event when selection changes
   */
  selectedRowId: PropTypes.any,
  /**
   * should cell height be dynamic
   */
  dynamicCellHeight: PropTypes.bool,
  /**
   * event when selection changes
   */
  onRowSelect: PropTypes.func,
  /**
   * table name
   */
  tableName: PropTypes.string,
};

Table.defaultProps = {
  headerHeight: 20,
  rowHeight: 30,
  onRowSelect: noop,
  selectedRowId: -1,
  dynamicCellHeight: false,
  tableName: '',
};

export default Table;
