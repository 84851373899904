import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUsersList } from '../../../../redux/tasks/tasksSlice';
import { NotificationManager } from 'react-notifications';
import { isNullOrWhitespace, isValidEmail } from '../SharedLogic';
import { SubmitHiddenButton } from '../../Components';
import { FormContent } from './FormContent';
import TabForm from '../TabForm';
import styles from './AddOrEditUser.module.css';
import { getUserName } from '../../../TasksList/TasksList.logic';
import { useDispatch } from 'react-redux';
import {
  setUserPreviousData,
  getUserPreviousData,
} from '../../../../redux/tasks/tasksSlice';

const AddOrEditUser = (props) => {
  const {
    tasksListLogic,
    email,
    setEmail,
    setEmailError,
    setGroupList,
    name,
    setName,
    group,
    setGroup,
    submitRef,
    selectedUser,
    setSelectedUser,
    isActive,
    setIsActive,
    isAdmin,
    setIsAdmin,
    isEdit,
    fetchData,
  } = props;
  const usersList = useSelector(selectUsersList);
  const dispatch = useDispatch();

  useEffect(() => {
    setGroupList([...new Set(usersList.map((item) => item?.Group))].sort());
  }, [setGroupList, usersList]);

  //  Add User
  const ADD_USER_SECURITY_GROUP_URL =
    'https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Members/groupId/49a843a5-4ca8-41c4-946a-10a4dd70adcf';

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(!(e.target.validity.valid && isValidEmail(emailValue)));
  };

  //  Edit User

  const selectEmail = (email) => {
    const user = usersList.find((user) => user.UserId === email);
    if (user) {
      const prevUserDataObj = {
        name: user?.Name,
        email: email,
        group: user?.Group,
        isActive: user?.IsActive,
        isAdmin: user?.IsAdmin,
      };
      dispatch(setUserPreviousData(prevUserDataObj));

      setSelectedUser(user);
      setEmail(email);
      setIsActive(user.IsActive);
      setIsAdmin(user.IsAdmin);
      setName(user.Name);
      setGroup(user.Group);
    } else {
      resetParameters();
    }
  };

  //  Common

  const resetParameters = () => {
    setEmail('');
    setName('');
    setGroup('');
  };

  const previousUserData = useSelector(getUserPreviousData);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate Email
    if (!isEdit) {
      if (!isValidEmail(email)) {
        NotificationManager.error(
          'Please enter a valid email address.',
          'Warning'
        );
        return;
      }

      if (
        usersList.some(
          (item) => item.UserId.toLowerCase() === email.toLowerCase()
        )
      ) {
        NotificationManager.error('Email already exists.', 'Warning');
        return;
      }
    }

    // Validate Name
    if (
      (!isEdit || selectedUser.Name !== name) &&
      usersList
        .map((item) => item?.Name && item?.Name.trim().toLowerCase())
        .includes(name && name?.trim().toLowerCase())
    ) {
      NotificationManager.error('Name already exists.', 'Warning');
      return;
    }

    // Validate Group
    if (isNullOrWhitespace(group)) {
      NotificationManager.error('User must be in group.', 'Warning');
      return;
    }

    const user = {
      email,
      name,
      group,
      isActive,
      isAdmin,
      isEdit,
    };

    const updatedBy = getUserName();
    const activityLogs = {
      operation: isEdit ? 'EditUser' : 'AddUser',
      updatedBy,
      latestData: {
        name,
        email,
        group,
        isActive,
        isAdmin,
      },
      previousData: isEdit ? previousUserData : {},
    };

    try {
      await tasksListLogic.postAddOrEditUser(user);
      await tasksListLogic.addOrEditActivityLogs(activityLogs);
      await tasksListLogic.sendActivityEmail(activityLogs);
      await fetchData();
      resetParameters();

      NotificationManager.success(
        `User ${isEdit ? 'edited' : 'added'} successfully`,
        'Success!'
      );
    } catch (error) {
      console.error(`Error ${isEdit ? 'editing' : 'adding'} user:`, error);
      NotificationManager.error(
        `An error occurred while ${
          isEdit ? 'editing' : 'adding'
        } the user. Please try again later.`,
        'Warning'
      );
    }
  };

  const additionalProps = {
    ...props,
    securityGroupUrl: ADD_USER_SECURITY_GROUP_URL,
    usersList,
    handleEmailChange,
    selectEmail,
  };

  return (
    <TabForm className={styles.tabForm} onSubmit={handleSubmit}>
      <FormContent {...additionalProps} />
      <SubmitHiddenButton type="submit" ref={submitRef} />
    </TabForm>
  );
};

export const AddUser = (props) => {
  return <AddOrEditUser {...props} />;
};

export const EditUser = (props) => {
  return <AddOrEditUser {...props} isEdit={true} />;
};
