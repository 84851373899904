import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
} from '@mui/material';
import PropTypes from 'prop-types';

const CampaignSummaryTable = ({
  previousEditCampaignSummary,
  latestEditCampaignSummary,
  previousTiers,
  latestTiers,
}) => {
  const headers = ['Field', 'Previous Data', 'Updated Data'];
  const rows = [
    {
      field: 'Campaign Owner',
      previous: previousEditCampaignSummary.campaignOwner,
      latest: latestEditCampaignSummary.campaignOwner,
      isLink: true,
    },
    {
      field: 'Campaign Group',
      previous: previousEditCampaignSummary.campaignGroup,
      latest: latestEditCampaignSummary.campaignGroup,
    },
    {
      field: 'Number of Tier 1 Labelers',
      previous: previousEditCampaignSummary.numberOfTier1Labelers,
      latest: latestEditCampaignSummary.numberOfTier1Labelers,
    },
    {
      field: 'Tier 2 Navigation Mode',
      previous: previousEditCampaignSummary.tier2NavigationMode,
      latest: latestEditCampaignSummary.tier2NavigationMode,
    },
    {
      field: 'Assisted work',
      previous: previousEditCampaignSummary.assistedWork,
      latest: latestEditCampaignSummary.assistedWork,
    },
    {
      field: 'Auto Correction',
      previous:
        previousEditCampaignSummary.disableAutoCorrection === false
          ? 'Enabled'
          : 'Disabled',
      latest:
        latestEditCampaignSummary.disableAutoCorrection === false
          ? 'Enabled'
          : 'Disabled',
    },
    {
      field: 'AI as additional Tier1',
      previous:
        previousEditCampaignSummary.aiAsAdditionalTier1 === true
          ? 'Enabled'
          : 'Disabled',
      latest:
        latestEditCampaignSummary.aiAsAdditionalTier1 === true
          ? 'Enabled'
          : 'Disabled',
    },
    {
      field: 'Hidden',
      previous: previousEditCampaignSummary.hidden === true ? 'Yes' : 'No',
      latest: latestEditCampaignSummary.hidden === true ? 'Yes' : 'No',
    },
    {
      field: 'Campaign Guidance',
      previous: previousEditCampaignSummary.campaignGuidance,
      latest: latestEditCampaignSummary.campaignGuidance,
      isHtml: true,
    },
    {
      field: 'Batches',
      previous: previousEditCampaignSummary.batches.sort(),
      latest: latestEditCampaignSummary.batches.sort(),
    },
    {
      field: 'Findings',
      previous: previousEditCampaignSummary.findings.sort(),
      latest: latestEditCampaignSummary.findings.sort(),
    },
    {
      field: 'Tier 1 Users',
      previous: previousTiers.tier1.sort(),
      latest: latestTiers.tier1.sort(),
    },
    {
      field: 'Tier 2 Users',
      previous: previousTiers.tier2.sort(),
      latest: latestTiers.tier2.sort(),
    },
    {
      field: 'Tier 3 Users',
      previous: previousTiers.tier3.sort(),
      latest: latestTiers.tier3.sort(),
    },
    {
      field: 'Supervisors',
      previous: previousTiers.supervisor.sort(),
      latest: latestTiers.supervisor.sort(),
    },
    {
      field: 'Watchers',
      previous: previousTiers.watcher.sort(),
      latest: latestTiers.watcher.sort(),
    },
  ];

  const filteredRows = rows.filter((row) => {
    if (Array.isArray(row.previous) && Array.isArray(row.latest)) {
      return row.previous.join(',') !== row.latest.join(',');
    }
    return row.previous !== row.latest;
  });
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 300,
        marginTop: '4px',
        boxShadow: 'unset',
        borderRadius: '0px',
      }}
    >
      <Table
        stickyHeader
        sx={{ '& td, & th': { border: '1px solid black', padding: '10px' } }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} style={{ fontWeight: 'bold' }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.field}</TableCell>
              <TableCell>
                {row.isLink ? (
                  <a href={row.previous}>{row.previous}</a>
                ) : row.isHtml ? (
                  <span dangerouslySetInnerHTML={{ __html: row.previous }} />
                ) : Array.isArray(row.previous) ? (
                  row.previous.map((item, i) => <div key={i}>{item}</div>)
                ) : (
                  row.previous
                )}
              </TableCell>
              <TableCell>
                {row.isLink ? (
                  <a href={row.latest}>{row.latest}</a>
                ) : row.isHtml ? (
                  <span dangerouslySetInnerHTML={{ __html: row.latest }} />
                ) : Array.isArray(row.latest) ? (
                  row.latest.map((item, i) => <div key={i}>{item}</div>)
                ) : (
                  row.latest
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CampaignSummaryTable.propTypes = {
  previousEditCampaignSummary: PropTypes.shape({
    campaignOwner: PropTypes.string.isRequired,
    campaignGroup: PropTypes.string.isRequired,
    numberOfTier1Labelers: PropTypes.string.isRequired,
    tier2NavigationMode: PropTypes.string.isRequired,
    assistedWork: PropTypes.string.isRequired,
    disableAutoCorrection: PropTypes.bool.isRequired,
    aiAsAdditionalTier1: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
    campaignGuidance: PropTypes.string.isRequired,
    batches: PropTypes.arrayOf(PropTypes.string).isRequired,
    findings: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  latestEditCampaignSummary: PropTypes.shape({
    campaignOwner: PropTypes.string.isRequired,
    campaignGroup: PropTypes.string.isRequired,
    numberOfTier1Labelers: PropTypes.string.isRequired,
    tier2NavigationMode: PropTypes.string.isRequired,
    assistedWork: PropTypes.string.isRequired,
    disableAutoCorrection: PropTypes.bool.isRequired,
    aiAsAdditionalTier1: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
    campaignGuidance: PropTypes.string.isRequired,
    batches: PropTypes.arrayOf(PropTypes.string).isRequired,
    findings: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  previousTiers: PropTypes.shape({
    tier1: PropTypes.arrayOf(PropTypes.string).isRequired,
    tier2: PropTypes.arrayOf(PropTypes.string).isRequired,
    tier3: PropTypes.arrayOf(PropTypes.string).isRequired,
    supervisor: PropTypes.arrayOf(PropTypes.string).isRequired,
    watcher: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  latestTiers: PropTypes.shape({
    tier1: PropTypes.arrayOf(PropTypes.string).isRequired,
    tier2: PropTypes.arrayOf(PropTypes.string).isRequired,
    tier3: PropTypes.arrayOf(PropTypes.string).isRequired,
    supervisor: PropTypes.arrayOf(PropTypes.string).isRequired,
    watcher: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default CampaignSummaryTable;
