import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TasksList from './pages/TasksList/TasksList';
import styles from './App.module.css';
import {
  setConfig,
  setCampaignGuidance,
  selectAdminRole,
  setAdminRole,
  setUsersList,
} from './redux/tasks/tasksSlice';
import LabelingToolData from './pages/LabelingToolData';
import { get, ipData } from './shared-logic/fetchApi';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import { useGetApiConfigs, useGetTaskListLogic } from './hooks';
import ManagementPage from './pages/Management/ManagementPage';
import { getUser } from './config/msalConfig';
export const fetchConfigContext = createContext();

function App() {
  const apiConfigs = useGetApiConfigs();
  const tasksListLogic = useGetTaskListLogic();
  const [error] = useState('');
  const dispatch = useDispatch();
  const isAdminRole = useSelector(selectAdminRole);
  const [triggerFetchConfig, setTriggerFetchConfig] = useState(false);
  const refetchConfig = useCallback(() => {
    setTriggerFetchConfig(!triggerFetchConfig);
  }, [triggerFetchConfig]);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const authUser = getUser();
        const userName = authUser?.username;

        const res = await get(`${apiConfigs.API_ENDPOINT}/fetchConfig`);
        const config = res.config;
        const campaignGuidance = res.campaignGuidance;
        dispatch(setConfig(config));
        dispatch(setCampaignGuidance(campaignGuidance));

        const listOfUsers = await tasksListLogic.getListOfUsers();
        dispatch(setUsersList(listOfUsers));

        const user = listOfUsers.find((user) => user.UserId === userName);

        dispatch(setAdminRole(user.IsAdmin));
      } catch (error) {
        const ip = await ipData();
        ip &&
          NotificationManager.error(
            `Could not fetch config, please verify you are properly connected to VPN, IP address: ${ip}`,
            'Warning',
            100000
          );
        console.error(`Error fetching config:`, error);
        throw new Error(`Error fetching config:`, error);
      }
    }
    fetchConfig();
  }, [apiConfigs.API_ENDPOINT, dispatch, tasksListLogic, refetchConfig]);

  return (
    <fetchConfigContext.Provider value={{ refetchConfig }}>
      <div className={styles.root}>
        <Switch>
          <Route path="/task">
            <LabelingToolData />
          </Route>
          <Route path="/manage">{isAdminRole && <ManagementPage />}</Route>
          <Route exact path="/">
            <TasksList error={error} />
          </Route>
        </Switch>
        <NotificationContainer />
      </div>
    </fetchConfigContext.Provider>
  );
}

export default App;
