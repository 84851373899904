import React, { Fragment, useContext, useEffect, useState } from 'react';
import styles from './AddOrEditCampaign.module.css';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import {
  getCampaignsPreviousData,
  selectConfig,
  selectUsersList,
} from '../../../../redux/tasks/tasksSlice';
import { useSelector } from 'react-redux';
import UpdateCampaignDialog from '../../Dialog/updateCampaignDialog/updateCampaignDialog';
import {
  USER_ROLES,
  tier2NavigationModeOptions,
  ASSISTED_WORKS,
  DEFAULT_LABELERS_NUMBER,
  isNullOrWhitespace,
} from '../SharedLogic';
import {
  setConfirmPopUpEnabled,
  confirmPopupEnabledSelector,
} from '../../../../redux/labelingTool/labelingToolSlice';

import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import CampaignSettings from './Steps/CampaignSettings';
import _ from 'lodash';
import SelectUsers from './Steps/SelectUsers';
import SelectFindings from './Steps/SelectFindings';
import SelectBatches from './Steps/SelectBatches';
import EditSelectBatches from './Steps/EditSelectBatches';
import TabForm from '../TabForm';
import { SubmitHiddenButton } from '../../Components';
import { getUserName } from '../../../TasksList/TasksList.logic';
import { HourglassProgress } from '../../../../components';
import classNames from 'classnames';
import EditCampaignSettings from './Steps/EditCampaignSettings';
import EditSelectUsers from './Steps/EditSelectUsers';
import EditSelectFindings from './Steps/EditSelectFindings';
import { isAddDetectionToAggreagation } from '../../../../config/configUtil';
import { useGetApiConfigs } from '../../../../hooks';
import { fetchConfigContext } from '../../../../App';
const steps = [
  'Select Campaign Settings',
  'Select Batches',
  'Select Findings',
  'Select Users',
];

const ASC_ORDER = 'asc';
const USER_ID_CELL = 'UserId';

const AddOrEditCampaign = (props) => {
  const {
    tasksListLogic,
    campaignsList,
    findingsList,
    editBatchesList,
    //batchesList,
    //preSelectedCampaignBatches,
    setPreSelectedCampaignBatches,
    selectedUsersAndRoles,
    setSelectedUsersAndRoles,
    campaignName,
    setCampaignName,
    campaignOwner,
    setCampaignOwner,
    campaignGroup,
    setCampaignGroup,
    // campaignsGroups,
    setCampaignsGroups,
    selectedBatches,
    setSelectedBatches,
    selectedFindings,
    setSelectedFindings,
    isHidden,
    setIsHidden,
    numberOfTier1Labelers,
    setNumberOfTier1Labelers,
    tier2NavigationMode,
    setTier2NavigationMode,
    assistedWork,
    setAssistedWork,
    aiAsAdditionalTier1,
    setAiAsAdditionalTier1,
    disableAutoCorrection,
    setDisableAutoCorrection,
    campaignGuidance,
    setCampaignGuidance,
    activeStep,
    setActiveStep,
    setPreSelectedFindings,
    setInactivatedFindings,
    // usersSortOrder,
    setUsersSortOrder,
    // groupsSortOrder,
    setGroupsSortOrder,
    // sortBy,
    setSortBy,
    // selectedFilteredGroups,
    setSelectedFilteredGroups,
    resetRef,
    setUpdatedNumberOfTier1Labellers,
    fetchData,
    tabSwitch,
    setTabSwitch,
  } = props;
  const [editCampaignSummary, setEditCampaignSummary] = useState({});
  const usersList = useSelector(selectUsersList);
  const { refetchConfig } = useContext(fetchConfigContext);
  const apiConfigs = useGetApiConfigs();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [showAlert, setShowAlert] = useState({});
  const [userAlertMessage, setUserAlertMessage] = useState({});
  const isLastStep = activeStep === steps.length - 1;
  const config = useSelector(selectConfig);
  const getRolesArray = () => {
    return USER_ROLES.map((role) => ({
      label: role,
      checked: false,
    }));
  };
  const dispatch = useDispatch();
  const enableUpdatePopUp = useSelector(confirmPopupEnabledSelector);
  useEffect(() => {
    dispatch(setConfirmPopUpEnabled(false));
  }, [dispatch]);
  useEffect(() => {
    setSelectedUsersAndRoles((prev) =>
      usersList.map((user) => {
        return {
          ...user,
          roles:
            prev.find((u) => u.UserId === user.UserId)?.roles ??
            getRolesArray(),
        };
      })
    );
  }, [setSelectedUsersAndRoles, usersList]);
  useEffect(() => {
    if (tabSwitch) {
      resetRef.current.click();
      setTabSwitch(false);
    }
  }, [tabSwitch, setTabSwitch, resetRef]);
  useEffect(() => {
    setCampaignsGroups(
      [...new Set(campaignsList.map((item) => item?.group))].sort()
    );
  }, [campaignsList, setCampaignsGroups]);

  const getSelectedRoles = (roles) => {
    const selectedRoles = [];
    roles.forEach((role) => {
      if (role.checked) {
        selectedRoles.push(role.label);
      }
    });
    return selectedRoles;
  };

  const getSelectedUsersAndRoles = () => {
    const selectedUsers = {};
    selectedUsersAndRoles.forEach((user) => {
      const selectedRoles = getSelectedRoles(user.roles);
      if (selectedRoles.length !== 0) {
        selectedUsers[user.UserId] = selectedRoles;
      }
    });
    return selectedUsers;
  };

  const previousCampaignsData = useSelector(getCampaignsPreviousData);
  const enableSubmit = (event) => {
    event.preventDefault();

    if (props.isEdit && checkIfCampaignUpdated()) {
      dispatch(setConfirmPopUpEnabled(true));

      return '';
    }
    return handleSubmit(event);
  };
  const handleSubmit = async (e) => {
    if (!props.isEdit) {
      e.preventDefault();
    }

    setSubmitInProgress(true);

    if (!props.isEdit && !selectedFindings.length) {
      NotificationManager.error(
        `Can't add campaign without findings.`,
        'Warning'
      );
      return;
    }

    const selectedUsers = getSelectedUsersAndRoles();

    const newCampaign = {
      isEdit: props.isEdit,
      campaignId: campaignsList.find((x) => x.name === campaignName)?.id,
      campaignName,
      campaignOwner,
      campaignGroup,
      usersAndRoles: selectedUsers,
      batches: selectedBatches,
      hidden: isHidden,
      numberOfTier1Labelers,
      tier2NavigationMode,
      assistedWork,
      aiAsAdditionalTier1,
      disableAutoCorrection,
      findings: selectedFindings,
      campaignGuidance,
    };

    const updatedBy = getUserName();
    const activityLogs = {
      operation: props.isEdit ? 'EditCampaign' : 'AddCampaign',
      updatedBy,
      latestData: {
        campaignId: campaignsList?.find((x) => x.name === campaignName)?.id,
        campaignName,
        campaignOwner,
        campaignGroup,
        usersAndRoles: selectedUsers,
        batches: selectedBatches,
        hidden: isHidden,
        numberOfTier1Labelers,
        tier2NavigationMode,
        assistedWork,
        aiAsAdditionalTier1,
        disableAutoCorrection,
        findings: selectedFindings,
        campaignGuidance,
      },
      previousData: props.isEdit ? previousCampaignsData : {},
    };
    if (
      props?.isEdit &&
      numberOfTier1Labelers <=
        config?.campaigns?.find(
          (x) => x['campaign_id'] === newCampaign.campaignId
        )?.params['number_of_tier1_labelers']
    ) {
      newCampaign['bucketName'] = apiConfigs?.BUCKET_NAME;
      newCampaign['addDetectionFile'] = isAddDetectionToAggreagation(
        config,
        newCampaign.campaignId
      )?.toString();
    }

    try {
      await tasksListLogic.postAddCampaign(newCampaign);
      if (props.isEdit) {
        const campaignConfig = config?.campaigns?.find(
          (x) => x.campaign_name === campaignName
        );
        const existingBatches = campaignConfig?.params?.batches || [];
        const batchedTobeRemoved = existingBatches.filter(
          (batch) => !selectedBatches.includes(batch)
        );

        batchedTobeRemoved.length > 0 &&
          (await tasksListLogic.removeBatchFromCampaign(
            batchedTobeRemoved,
            campaignConfig?.campaign_id
          ));
      }

      const activityLogEmailData = {
        ...activityLogs,
        latestData: {
          ...activityLogs.latestData,
          findings: mapFindingsToNames(activityLogs.latestData.findings),
          batches: mapBatchesToNames(activityLogs.latestData.batches),
        },
        previousData: activityLogs.previousData
          ? {
              ...activityLogs.previousData,
              findings: mapFindingsToNames(activityLogs.previousData.findings),
              batches: mapBatchesToNames(activityLogs.previousData.batches),
            }
          : {},
      };
      await tasksListLogic.addOrEditActivityLogs(activityLogs);
      await tasksListLogic.sendActivityEmail(activityLogEmailData);
      await resetParameters();
      await refetchConfig();

      if (props.isEdit) {
        NotificationManager.success('Campaign Edited successfully!', 'Success');
      } else {
        NotificationManager.success(
          'Campaign Created successfully!',
          'Success'
        );
      }
    } catch (error) {
      NotificationManager.error(
        `An error occurred while adding the campaign: \n  ${error}. \n  Please try again later.`,
        'Warning'
      );
    }
    setSubmitInProgress(false);
  };

  const mapIdsToNames = (list, idKey, nameKey, ids) => {
    return ids.map((id) => {
      const item = list.find((element) => element[idKey] === id);
      return item ? item[nameKey] : id;
    });
  };

  const mapFindingsToNames = (findings) => {
    return mapIdsToNames(findingsList, 'FindingId', 'FindingName', findings);
  };

  const mapBatchesToNames = (batches) => {
    return mapIdsToNames(editBatchesList, 'BatchId', 'BatchName', batches);
  };

  const checkIfCampaignUpdated = () => {
    const selectedUsers = getSelectedUsersAndRoles();
    let newCampaignData = {
      campaignId: campaignsList.find((x) => x.name === campaignName)?.id,
      campaignName,
      campaignOwner,
      campaignGroup,
      usersAndRoles: selectedUsers,
      batches: selectedBatches,
      hidden: isHidden,
      numberOfTier1Labelers,
      tier2NavigationMode,
      assistedWork,
      aiAsAdditionalTier1,
      disableAutoCorrection,
      findings: selectedFindings,
      campaignGuidance,
    };
    setEditCampaignSummary({
      previousEditCampaignSummary: previousCampaignsData,
      latestEditCampaignSummary: newCampaignData,
    });
    return !_.isEqual(newCampaignData, previousCampaignsData);
  };
  const resetParameters = async () => {
    if (!props.isEdit) {
      setSelectedUsersAndRoles([]);
    }
    setCampaignName('');
    setCampaignOwner(getUserName());
    setCampaignGroup('');
    setSelectedFindings([]);
    setIsHidden(false);
    setSelectedBatches([]);
    setNumberOfTier1Labelers(DEFAULT_LABELERS_NUMBER);
    setTier2NavigationMode(tier2NavigationModeOptions[0]);
    setAssistedWork(ASSISTED_WORKS[0]);
    setAiAsAdditionalTier1(false);
    setDisableAutoCorrection(false);
    setCampaignGuidance('');
    setActiveStep(0);
    setUsersSortOrder(ASC_ORDER);
    setGroupsSortOrder(ASC_ORDER);
    setSortBy(USER_ID_CELL);
    setSelectedFilteredGroups([]);
    dispatch(setConfirmPopUpEnabled(false));
    if (props.isEdit) {
      setPreSelectedCampaignBatches([]);
      setInactivatedFindings([]);
      setPreSelectedFindings([]);
      setUpdatedNumberOfTier1Labellers(0);
      setShowAlert({});
      setUserAlertMessage({});
      setAlertMessage({});
      setAlertVisible({});
    }
    await fetchData();
  };

  const isAtLeastOneUserSelected = () => {
    if (props.isEdit) return true;
    let roleSelected = false;
    selectedUsersAndRoles.forEach((user) => {
      user.roles.forEach((role) => {
        if (role.checked) {
          roleSelected = true;
          return;
        }
      });
    });
    return roleSelected;
  };

  const isAtLeastOneFindingSelected = () => {
    return selectedFindings.length !== 0;
  };

  const isAtLeastOneBatchSelected = () => {
    return selectedBatches.length !== 0;
  };

  const validateCampaignSettingsTab = () => {
    const validateCampaignName = () => {
      return !isNullOrWhitespace(campaignName);
    };
    const validateCampaignOwner = () => {
      return !isNullOrWhitespace(campaignOwner);
    };

    const isValidate = validateCampaignName() && validateCampaignOwner();
    let msg = null;
    if (!isValidate) {
      if (!validateCampaignName()) {
        msg = 'Fill Campaign Name';
      } else if (!validateCampaignOwner()) {
        msg = 'Fill Campaign Owner';
      }
    }

    return { isValidate, msg };
  };

  const validateBatchesTab = () => {
    const isValidate = isAtLeastOneBatchSelected();
    const msg = 'Select At Least One Batch';
    return { isValidate, msg };
  };

  const validateFindingsTab = () => {
    const isValidate = isAtLeastOneFindingSelected();
    const msg = 'Select At Least One Finding';
    return { isValidate, msg };
  };

  const validateUsersTab = () => {
    const isValidate = isAtLeastOneUserSelected();
    const msg = 'Select At Least One User';
    return { isValidate, msg };
  };

  const validateStep = (index) => {
    const ErrorStep = ({ msg }) => (
      <Typography variant="caption" color="error">
        {msg}
      </Typography>
    );

    const validators = [
      validateCampaignSettingsTab,
      validateBatchesTab,
      validateFindingsTab,
      validateUsersTab,
    ];
    if (index === steps.length) {
      return validators.every((v) => {
        return v().isValidate;
      });
    }

    if (activeStep > index) {
      const { isValidate, msg } = validators[index]();
      if (!isValidate) {
        return <ErrorStep msg={msg} />;
      }
    }

    return null;
  };

  const validateActiveStep = (activeStep) => {
    if (props.isEdit && activeStep === 0) {
      const { isValidate } = validateCampaignSettingsTab();
      return !isValidate;
    }
  };
  const handleNext = (e) => {
    e.stopPropagation();
    setActiveStep((prevActiveStep) => ++prevActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => --prevActiveStep);
  };

  const handleChangeCampaignGuidance = (value) => {
    setCampaignGuidance(value);
  };

  const stepsComponents = [
    <CampaignSettings
      {...props}
      handleChangeCampaignGuidance={handleChangeCampaignGuidance}
    />,
    <SelectBatches {...props} />,
    <SelectFindings {...props} />,
    <SelectUsers {...props} />,
  ];
  const [alertVisible, setAlertVisible] = useState({});
  const [alertMessage, setAlertMessage] = useState({});
  const editStepsComponents = [
    <EditCampaignSettings
      {...props}
      handleChangeCampaignGuidance={handleChangeCampaignGuidance}
      fetchData={fetchData}
    />,
    <EditSelectBatches
      {...props}
      campaignId={campaignsList.find((x) => x.name === campaignName)?.id}
      alertVisible={alertVisible}
      setAlertVisible={setAlertVisible}
      alertMessage={alertMessage}
      setAlertMessage={setAlertMessage}
    />,
    <EditSelectFindings {...props} />,
    <EditSelectUsers
      {...props}
      showAlert={showAlert}
      setShowAlert={setShowAlert}
      userAlertMessage={userAlertMessage}
      setUserAlertMessage={setUserAlertMessage}
    />,
  ];
  return (
    <TabForm
      className={styles.tabForm}
      onSubmit={props?.isEdit ? enableSubmit : handleSubmit}
    >
      <Stepper className={styles.stepper} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          const stepValidation = validateStep(index);
          if (stepValidation) {
            labelProps.optional = stepValidation;
            labelProps.error = true;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        <TabForm className={styles.stepForm}>
          {submitInProgress ? (
            <div className={styles.hourglassProgress}>
              <HourglassProgress />
            </div>
          ) : props.isEdit ? (
            editStepsComponents[activeStep]
          ) : (
            stepsComponents[activeStep]
          )}
        </TabForm>
        <Box className={styles.buttonsContainer}>
          <SubmitHiddenButton ref={resetRef} onClick={resetParameters} />
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            className={styles.buttonBack}
          >
            Back
          </Button>
          <Box className={styles.buttonSpacer} variant="outlined" />
          <div
            className={classNames({
              [styles.hiddenBtn]: isLastStep,
              [styles.visibleBtn]: !isLastStep,
            })}
          >
            <Button
              onClick={handleNext}
              variant="outlined"
              disabled={validateActiveStep(activeStep)}
            >
              Next
            </Button>
          </div>
          <div
            className={classNames({
              [styles.hiddenBtn]: !isLastStep,
              [styles.visibleBtn]: isLastStep,
            })}
          >
            <Button
              type="submit"
              disabled={!validateStep(steps.length) || submitInProgress}
              variant="outlined"
            >
              Finish
            </Button>
          </div>
        </Box>
      </Fragment>

      {enableUpdatePopUp && (
        <UpdateCampaignDialog
          confirmChanges={handleSubmit}
          activityLogs={editCampaignSummary}
          findingsList={findingsList}
          editBatchesList={editBatchesList}
        />
      )}
    </TabForm>
  );
};

export const AddCampaign = (props) => {
  return <AddOrEditCampaign {...props} />;
};

export const EditCampaign = (props) => {
  return <AddOrEditCampaign {...props} isEdit={true} />;
};
