const logsState = {
  isEnabled: true,
  logs: [],
  maxNumLogs: 1200,
};

const userActionLogs = {
  setIsEnabled: (enabled) => {
    logsState.isEnabled = enabled;
  },
  setMaxNumLogs: (max) => {
    logsState.maxNumLogs = max;
  },
  getLogs: () => logsState.logs,
  addActionLog: (msg) => {
    const addLog = (msg) =>
      logsState.logs.push(`${new Date().toLocaleString()}: ${msg}`);
    if (logsState.isEnabled) {
      if (logsState.logs.length >= logsState.maxNumLogs) {
        addLog('clearing, reached max');
        userActionLogs.clearLogs();
      }
      addLog(msg);
    }
  },
  clearLogs: () => {
    if (logsState.isEnabled) {
      logsState.logs = [];
    }
  },
};

export default userActionLogs;
