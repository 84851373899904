import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import AutocompleteOrCreateNew from '../../../../../components/AutocompleteOrCreateNew';
import { LabeledSwitch } from '../../../Components';
import { disableEnter } from '../../SharedLogic';

const EditUserForm = ({
  usersList,
  email,
  selectEmail,
  name,
  setName,
  group,
  setGroup,
  groupList,
  isActive,
  setIsActive,
  isAdmin,
  setIsAdmin,
  selectedFilteredGroups,
  setSelectedFilteredGroups,
}) => {
  const filteredUsers = selectedFilteredGroups.length
    ? usersList.filter((user) => selectedFilteredGroups.includes(user.Group))
    : usersList;
  const prevNameRef = useRef(name);
  const [inputNameValue, setInputNameValue] = useState(name || '');
  const [displayNameValue, setDisplayNameValue] = useState({
    Name: name,
  });
  useEffect(() => {
    setDisplayNameValue({ Name: name });
  }, [name]);
  const handleGroupChange = (event, newValue) => {
    setSelectedFilteredGroups(newValue.sort());
    const selectedEmailBelongsToGroup = filteredUsers.find((user) => {
      return user?.UserId === email;
    });
    if (
      selectedEmailBelongsToGroup &&
      !newValue.includes(selectedEmailBelongsToGroup?.Group)
    ) {
      selectEmail('');
    }
  };

  const handleEmailChange = (event, newValue) => {
    selectEmail(newValue ? newValue.UserId : '');
  };

  const handleNameChange = (event, newValue) => {
    if (newValue && prevNameRef.current !== newValue.Name) {
      setName(newValue ? newValue.Name : '');
      selectEmail(newValue.UserId);
      prevNameRef.current = name;
    }
  };

  return (
    <>
      <Autocomplete
        multiple
        options={groupList}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Filter by Group" />
        )}
        value={selectedFilteredGroups}
        onChange={handleGroupChange}
      />

      <Autocomplete
        options={filteredUsers}
        getOptionLabel={(option) => option.UserId}
        value={filteredUsers.find((user) => user.UserId === email) || null}
        onChange={handleEmailChange}
        renderInput={(params) => (
          <TextField {...params} label="Email" required variant="outlined" />
        )}
        selectOnFocus
        handleHomeEndKeys
        disableClearable
      />

      <Autocomplete
        required
        value={displayNameValue}
        onChange={handleNameChange}
        inputValue={inputNameValue}
        onInputChange={(event, newInputValue) => {
          if (email) {
            setName(newInputValue);
          }
          setInputNameValue(newInputValue);
        }}
        selectOnFocus
        handleHomeEndKeys
        disableClearable
        clearOnBlur
        options={filteredUsers}
        getOptionLabel={(option) => option.Name || ''}
        freeSolo={email ? true : false}
        renderInput={(params) => (
          <TextField
            required
            onKeyDown={disableEnter}
            {...params}
            label="Display Name"
          />
        )}
      />
      {email && (
        <>
          <AutocompleteOrCreateNew
            selectedValue={group}
            setSelectedValue={setGroup}
            values={groupList}
            fieldlabel="Group"
            required={true}
          />
          <LabeledSwitch
            checked={isActive}
            setChecked={setIsActive}
            label={'Active'}
          />
          <LabeledSwitch
            checked={isAdmin}
            setChecked={setIsAdmin}
            label={'Admin'}
          />
        </>
      )}
    </>
  );
};

export default EditUserForm;
